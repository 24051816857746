import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as LocationStep } from "../img/locationStep.svg";
import { ReactComponent as TimeStep } from "../img/time.svg";
import { ReactComponent as InterestsStep } from "../img/interests.svg";

import Location from "../components/steps/ChooseLocation";
import TimePeriod from "../components/steps/ChooseTime";
import Interests from "../components/steps/ChooseInterests";

import { INTERESTS_LIMITE } from '../utils/stepsHelper';

const TIME_RANGE = 120

const timeRangeValidation = ({ startHour, endHour, startMinute, endMinute }) => {
  if (isEmpty(startHour) || isEmpty(endHour) || isEmpty(startMinute) || isEmpty(endMinute)) {
    return false
  }
  const start = Number(startHour) * 60 + Number(startMinute);
  const end = Number(endHour) * 60 + Number(endMinute)
  return end - start >= TIME_RANGE
}

const getStepConfigs = ({
  step,
  geoState,
  setGeoState,
  timeWindow,
  setTimeWindow,
  selectedInterests,
  updateInterest
}) => {
  switch (step) {
    case 0:
      return {
        component: Location,
        componentProps: { geoState, setGeoState },
        icon: LocationStep,
        next: "Get Started",
        validate: !isNil(geoState.latitude) && !isNil(geoState.longitude),
      };
    case 1:
      return {
        component: TimePeriod,
        componentProps: { timeWindow, setTimeWindow },
        icon: TimeStep,
        prev: "Back",
        next:"Pick my interests",
        validate: timeRangeValidation(timeWindow)
      };
    case 2:
      return {
        component: Interests,
        componentProps: { selectedInterests, updateInterest },
        icon: InterestsStep,
        prev: "Back",
        next: "Create my tailor-made guide",
        validate: selectedInterests.length >= 1 && selectedInterests.length <= INTERESTS_LIMITE
      };
    default:
      return {};
  }
};

const STEP_COUNT = 3;

export { getStepConfigs, STEP_COUNT, TIME_RANGE };
